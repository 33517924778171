<template>
    <div>
        <div class="header">
            <span class="header_txt">支付方式</span>
        </div>
        <div class="content">
            <div class="left">
                <h2>微信支付</h2>
                <p>资金结算至店铺余额，结算时扣除0.6%交易手续费 (微信收取)。(支付宝内无法使用微信支付)</p>
            </div>
            <div class="right">
                <i-switch v-model="switchwechat" @on-change="change"></i-switch>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <h2>余额支付</h2>
                <p>使用储值的余额进行支付</p>
            </div>
            <div class="right">
                <i-switch v-model="switchbalance" @on-change="change"></i-switch>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <h2>线下支付</h2>
                <p>店铺与客户私下完成交易</p>
            </div>
            <div class="right">
                <i-switch v-model="switchOffline" @on-change="change"></i-switch>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <h2>收益支付</h2>
                <p>使用储值的可提现进行支付</p>
            </div>
            <div class="right">
                <i-switch v-model="switchincome" @on-change="change"></i-switch>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                switchwechat: false,
                switchbalance: false,
                switchOffline: false,
                switchincome: false,
            }
        },
        methods: {
            change (status) {
                this.$Message.info('开关状态：' + status);
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.content{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #eee;
    padding: 20px;
}
// .left{
// }
</style>